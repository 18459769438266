import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Kid friendly lasagna`}</h1>
    <h3>{`INGREDIENTS`}</h3>
    <p>{`Purchase`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Beef`}</strong>{`, 1 lb, `}<em parentName="p">{`ground`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Spaghetti Sauce`}</strong>{`, 1 jar (24oz)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Diced tomatoes`}</strong>{`, 1 can (14.5oz), with basil, garlic & oregano; `}<em parentName="p">{`undrained`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ricotta`}</strong>{`, 8oz, `}<em parentName="p">{`part-skim`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Lasagna noodles`}</strong>{`, 10, `}<em parentName="p">{`uncooked`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Mozarella cheese`}</strong>{`, 3 cups, `}<em parentName="p">{`shredded`}</em></p>
      </li>
    </ul>
    <p>{`On hand`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Water`}</strong>{`, .5 cup`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Egg`}</strong>{`, 1, `}<em parentName="p">{`beaten`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Parmesan`}</strong>{`, .25 cup, `}<em parentName="p">{`grated`}</em></p>
      </li>
    </ul>
    <h3>{`DIRECTIONS`}</h3>
    <ol>
      <li parentName="ol">{`Preheat the oven to 400° (press `}<inlineCode parentName="li">{`bake`}</inlineCode>{` then press `}<inlineCode parentName="li">{`four`}</inlineCode>{`, `}<inlineCode parentName="li">{`zero`}</inlineCode>{`, `}<inlineCode parentName="li">{`zero`}</inlineCode>{`, `}<inlineCode parentName="li">{`start`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Make the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode></span></li>
    </ol>
    <ul>
      <li parentName="ul">{`Get a wooden spoon and the large metal mixing bowl 🥣 `}</li>
      <li parentName="ul">{`Put the `}<inlineCode parentName="li">{`spaghetti sauce`}</inlineCode>{`, `}<inlineCode parentName="li">{`tomatoes`}</inlineCode>{` 🥫 `}<inlineCode parentName="li">{`beef`}</inlineCode>{` 🥩 and `}<inlineCode parentName="li">{`water`}</inlineCode>{` 🚰 into the bowl. `}</li>
      <li parentName="ul">{`Use the spoon to break apart the beef into pieces the size of lego guys`}</li>
      <li parentName="ul">{`Mix it all together until it's pretty even. `}</li>
      <li parentName="ul">{`Leave it.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Make the `}<inlineCode parentName="li">{`CHEESE TOPPING`}</inlineCode></li>
    </ol>
    <ul>
      <li parentName="ul">{`Get a whisk and the small metal mixing bowl 🥣 `}</li>
      <li parentName="ul">{`Mix together the `}<inlineCode parentName="li">{`egg`}</inlineCode>{` 🥚 `}<inlineCode parentName="li">{`ricotta`}</inlineCode>{`, `}<inlineCode parentName="li">{`parmesan`}</inlineCode>{` 🧀 using the whisk. `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Stack the layers on top of each other:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`spread some `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode></span>{` over the bottom of the lasagna dish so it covers the bottom but not deep`}</li>
      <li parentName="ul">{`Put 5 of the `}<inlineCode parentName="li">{`uncooked noodles`}</inlineCode>{` over the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode></span>{` to cover it. Breaking them is ok.`}</li>
      <li parentName="ul">{`Press the `}<inlineCode parentName="li">{`noodles`}</inlineCode>{` into the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode>{`.`}</span>{` Squishy. Do not. Play. With. It. 🐒`}</li>
      <li parentName="ul">{`Spread the `}<inlineCode parentName="li">{`CHEESE TOPPING`}</inlineCode>{` over the `}<inlineCode parentName="li">{`noodles`}</inlineCode>{`, then sprinkle three handfuls of the `}<inlineCode parentName="li">{`mozzarella`}</inlineCode>{` over it`}</li>
      <li parentName="ul">{`Top with more of the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode></span>{` and the remaining `}<inlineCode parentName="li">{`noodles`}</inlineCode>{` (breaking to fit as needed). `}</li>
      <li parentName="ul">{`Press the noodles into the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode>{`.`}</span>{` `}</li>
      <li parentName="ul">{`Spread the rest of the `}<span style={{
          fontWeight: 700,
          color: "tomato"
        }}><inlineCode parentName="li">{`MEAT SAUCE`}</inlineCode></span>{` over the noodles.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Cover the baking dish with aluminum foil.`}</li>
      <li parentName="ol">{`Have mom or dad  help you put the heavy dish in the hot 🔥 oven. Set a timer for 45 minutes!`}</li>
      <li parentName="ol">{`Have mom or dad remove the hot 🔥 baking dish from oven (it will be very hot!!!). Check that the noodles are not hard, if they are, put it back in and set anothe timer for 10 minutes.`}</li>
      <li parentName="ol">{`CAREFULLY 🔥 Take the tinfoil off and sprinkle the top with the rest of the mozzarella. `}</li>
      <li parentName="ol">{`CAREFULLY 🔥 Put the tinfoil back on top and let it stand for 15 minutes before serving.`}</li>
    </ol>
    <h3>{`Yield: 12  servings`}</h3>
    <p>{`Nutrients per serving:
Calories 300
Total Fat 9 g
Saturated Fat 5 g
Cholesterol 40 mg
Sodium 260 mg
Carbohydrate 38 g
Fiber 1 g
Sugars 3 g (includes 0 g added sugar)
Protein 16 g`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      